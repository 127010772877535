import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { readLoginError } from '@/store/main/getters';
import { mainStore } from '@/store';
let Login = class Login extends Vue {
    constructor() {
        super(...arguments);
        this.login = '';
        this.password = '';
        this.valid = true;
        this.showPassword = false;
        this.isLoading = false;
        this.admin = false;
        this.otp = '';
        this.accessError = null;
        this.loading2FA = false;
        this.companySelected = null;
        this.userSelected = null;
        this.isAluno = false;
    }
    get companiesToLogin() {
        return mainStore.companiesToLogin;
    }
    get usersToLogin() {
        return mainStore.usersToLogin;
    }
    get francisingName() {
        if (this.isKnnUrl) {
            return 'Phenom';
        }
        return 'KNN';
    }
    get isKnnUrl() {
        return window.location.hostname.includes('knn');
    }
    get isPhenomUrl() {
        return window.location.hostname.includes('phenom');
    }
    get isLogin() {
        return this.$route.path.includes('login');
    }
    get isPortal() {
        return this.$route.path.includes('portal');
    }
    get loginError() {
        return readLoginError(this.$store);
    }
    get habilitaLogin2FA() {
        return mainStore.habilitadoLogin2FA;
    }
    get currentUser() {
        return mainStore.userProfile;
    }
    get isFormValid() {
        return this.login.trim() !== '' && this.password.trim() !== '';
    }
    watchInputs() {
        if (this.login === null)
            this.login = '';
        if (this.password === null)
            this.password = '';
    }
    async submit() {
        if (!this.isFormValid) {
            return;
        }
        if (this.companiesToLogin.length > 1 && !this.companySelected) {
            return;
        }
        if (this.usersToLogin.length > 1 && !this.userSelected) {
            return;
        }
        this.isLoading = true;
        // Executa o reCAPTCHA e realiza o login
        // @ts-ignore
        await grecaptcha.ready(async () => {
            // @ts-ignore
            const token = await grecaptcha.execute('6LfBEfIiAAAAABz3DtRSX1mM8yGSI_3iniAYVrQm', { action: 'submit' });
            if (this.isPortal) {
                const userId = this.usersToLogin.length > 1 ? this.userSelected.id : 0;
                await mainStore.logInAluno({
                    username: this.login,
                    escola: this.isKnnUrl ? 'knn' : 'phenom'
                });
                if (this.currentUser) {
                    const isAluno = this.currentUser.is_aluno;
                    if (!isAluno) {
                        this.isLoading = false;
                        this.accessError = 'Somente alunos podem acessar.';
                        await mainStore.logOut();
                        return;
                    }
                }
            }
            else {
                const companyId = this.companiesToLogin.length > 1 ? this.companySelected.company_id : 0;
                await mainStore.logIn({
                    company_id: companyId,
                    username: this.login,
                    password: this.password,
                    admin: this.admin,
                    gToken: token,
                    codeTwoFA: this.otp || ''
                });
            }
            this.isLoading = false;
            this.accessError = null;
        });
    }
    async mounted() {
        this.admin = this.$router.currentRoute.query.admin === 'true';
    }
};
Login = __decorate([
    Component
], Login);
export default Login;
